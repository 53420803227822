import { IUfinetSelectOption } from "ufinet-web-functions"

export class Country {
    id: string
    name: string
    isoShort: string
    isoLong: string

    constructor( id: string, name: string, isoShort: string, isoLong: string ) {
        this.id = id
        this.name = name
        this.isoShort = isoShort
        this.isoLong = isoLong
    }

    static mapCountryToSelectOption(self: Country): IUfinetSelectOption {
        return {
            label: self.name,
			value: self.id,
        }
    }
}
