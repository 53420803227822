import { useContext, useEffect, useState } from 'react'
import { HttpClientsRepository } from 'src/modules/clients/infrastructure/HttpClientsRepository'
import { IIncidentsFilter } from 'src/interfaces/clientRepository/IClientRepository'
import { AuthContext } from 'ufinet-web-functions'

interface UseIncidentsFilterResult {
	incidentsFilter: IIncidentsFilter
	loading: boolean
	error: boolean
}

export const useIncidentsFilter = (): UseIncidentsFilterResult => {
	const authData = useContext(AuthContext)
	const [incidentsFilter, setIncidentsFilter] = useState<IIncidentsFilter>({
		corporateGroupIdsList: [],
		customerIds: [],
		countryIds: [],
	})
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)

	useEffect(() => {
		const clientsRepository = HttpClientsRepository(authData)

		const loadPermissions = async () => {
			try {
				setLoading(true)
				setError(false)

				const [permissions, corpsGroup] = await Promise.all([
					clientsRepository.findAll(),
					clientsRepository.findCorporatesGroup(),
				])

				const corporateGroupsMap = new Map()
				const customerIdsMap = new Map()
				const countryIdsMap = new Map()

				corpsGroup.forEach((group) => {
					corporateGroupsMap.set(group.id, { label: group.name, value: group.id })
				})

				permissions.forEach((permission) => {
					const existingGroup = corporateGroupsMap.get(permission.corporateGroupId)
					if (existingGroup) {
						corporateGroupsMap.set(permission.corporateGroupId, {
							label: existingGroup.label,
							value: permission.corporateGroupId,
						})
					}

					customerIdsMap.set(permission.id, {
						label: permission.name,
						value: permission.id,
					})

					countryIdsMap.set(permission.countryId, {
						label: permission.countryId,
						value: permission.countryId,
					})
				})

				const finalFilter: IIncidentsFilter = {
					corporateGroupIdsList: [...corporateGroupsMap.values()],
					customerIds: [...customerIdsMap.values()],
					countryIds: [...countryIdsMap.values()],
				}

				setIncidentsFilter(finalFilter)
			} catch (error) {
				console.error('Error fetching incidents filter:', error)
				setError(true)
				setIncidentsFilter({
					corporateGroupIdsList: [],
					customerIds: [],
					countryIds: [],
				})
			} finally {
				setLoading(false)
			}
		}

		loadPermissions()
	}, [authData])

	return { incidentsFilter, loading, error }
}
