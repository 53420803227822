import { Client } from 'src/modules/clients/domain/Client'
import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'

import { CountriesRepository } from '../domain/repository/CountriesRepository'

export const HttpCountryRepository: (authData: IAuthData) => CountriesRepository = (authData) => {
    const url = `${process.env.REACT_APP_URL_BACK_UFINET_HUB}api`

    const fetchOptions: FetchOptions = {
        ...defaultOptionsFetch,
        authenticationData: authData,
        sendApiKey: true,
        apiKey: process.env.REACT_APP_API_KEY,
    }

    return {        
        findCountries: async (options?: FetchOptions): Promise<Client[]> =>
                    await FetchAPI.get(`${url}/countries`, {
                        ...fetchOptions,
                        ...options,
                    })
    }
}
