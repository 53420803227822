import { useQuery } from "react-query"
import { Country } from "../domain/Country"
import { CountriesRepository } from "../domain/repository/CountriesRepository"

export const useCountryFindCountries = (
    countriesRepository: CountriesRepository,
    {
        onSuccess,
        onError,
    }: {
        onSuccess?: (reportType: Country[]) => void
        onError?: (error: unknown) => void
    }
) => {
    return useQuery('country-find-all-query', () => countriesRepository.findCountries(), {
        staleTime: 0,
        onSuccess,
        onError,
    })
}